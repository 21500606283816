/* eslint-disable eqeqeq */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import React, { FC, useState } from "react";

import classes from "./style";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { LoggedType, DISCONNECT } from "../../slices/loginSlice";
import { LOAD_CARD } from "../../slices/reservationSlice";
import { RootState } from "../../store";
import {
  Dialog,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import Shopping from "../shopping";
import { needConfirmation, showMessage } from "../../services/popupService";
import { getSimpleDate } from "../../utils/dateUtils";
import { NavLink } from "react-router-dom";
import { getPayResult } from "../../services/payService";
import { PaiementResultType } from "../../models/Pay";
import New from '../../images/new.png';

const Header: FC<{}> = () => {
  const history = useHistory();
  const state = useSelector((s: RootState) => s);
  const logged = state.login.loggedType;
  const dispatch = useDispatch();

  const [cardAnchor, setCardAnchor] = useState<any>(undefined);
  const location = useLocation().pathname;

  const [openShoppingModal, setOpenShoppingModal] =
    React.useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const retrievePayment = async (refId: string) => {
    try {
      const pay = await getPayResult(refId);
      if (pay?.result === PaiementResultType.Success) {
        if (pay.packUsed > 0 || pay.finalPrice > 0) {
          const s = 'Votre réservation a bien été effectuée ! '
            + (pay.packUsed > 0 ? 'Packs utilisés : ' + pay.packUsed : '');

          showMessage(s);
        }
      } else {
        showMessage('Votre réservation a été annulé.');
      }
    } catch {

    }
  }

  useEffect(() => {
    if (state.login.loggedType == LoggedType.Client) {
      dispatch(LOAD_CARD());
    }
  }, [location]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const r = query.get("redirect");

    if (r === "shop" && !openShoppingModal && location !== '/acces-membre') {
      setOpenShoppingModal(true);
      return;
    }

    const p = query.get("payment_success");

    if ((p?.length ?? 0) > 0) {
      retrievePayment(p ?? '');
    }
  }, [location]);

  const drawerWidth = 240;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const addMonths = (date: Date) => {
    date.setMonth(date.getMonth() + 12);
    return getSimpleDate(date);
  };

  const drawer = (
    <Box sx={classes.menuDrawer}>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <img
          alt="HopLa logo"
          src="Logo.svg"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push("/")}
        />{" "}
        <IconButton aria-label="close drawer" onClick={handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider />
      <List>
        <ListItem key="articles" disablePadding>
          <ListItemButton>
            <ListItemText
              onClick={(e) => {
                e.preventDefault();
                history.push("/articles");
              }}
              primary="Articles"
            />
          </ListItemButton>
        </ListItem>

        {logged !== LoggedType.Coach ? (
          <ListItem
            key="reservation-seance"
            disablePadding
            onClick={(e) => {
              e.preventDefault();
              history.push("/reservation");
            }}
          >
            <ListItemButton>
              <ListItemText primary="Réserver une séance" />
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem
            key="planning"
            disablePadding
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}
          >
            <ListItemButton>
              <ListItemText primary="Planning" />
            </ListItemButton>
          </ListItem>
        )}
        {logged !== LoggedType.None ? (
          <>
            <ListItem
              key="mon-profile"
              disablePadding
              onClick={(e) => {
                e.preventDefault();
                history.push(
                  "/" +
                  (logged == LoggedType.Client ? "client" : "coach") +
                  "?id=" +
                  (logged == LoggedType.Client
                    ? state.login.client?.id
                    : state.login.coach?.id) ?? 0
                );
              }}
            >
              <ListItemButton>
                <ListItemText primary="Mon profil" />
              </ListItemButton>
            </ListItem>

            <ListItem
              key="se-deconnecter"
              disablePadding
              onClick={(e) =>
                needConfirmation(
                  "Êtes vous sûr de vouloir vous deconnecter ?",
                  () => {
                    e.preventDefault();
                    dispatch(DISCONNECT());
                    history.push("/");
                  },
                  () => { }
                )
              }
            >
              <ListItemButton>
                <ListItemText primary="Se Déconnecter" />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <ListItem
            key="connexion"
            disablePadding
            onClick={(e) => {
              e.preventDefault();
              history.push("/acces-membre");
            }}
          >
            <ListItemButton>
              <ListItemText primary="Connexion" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={classes.header}>
      <AppBar component="nav" sx={{
        height: {
          sm: '150px',
          xs: '80px'
        }
      }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" justifyContent='center' width="100%" alignItems='center' ml='5%' mt={2}>
            <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: { sm: "center" } }}>
              <NavLink to="/" exact>
                Accueil
              </NavLink>
              <NavLink to="/articles">
                <span>Articles</span>
              </NavLink>
              <img
                alt="HopLa logo"
                src="Logo.svg"
                height="100"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push("/")}
              />
              {logged !== LoggedType.Coach ? (
                <NavLink to="/reservation" exact>
                  Réserver une séance
                </NavLink>
              ) : (
                <NavLink to="/" exact>
                  Planning
                </NavLink>
              )}
              {logged !== LoggedType.None ? (
                <>
                  <NavLink
                    to={
                      "/" +
                      (logged == LoggedType.Client ? "client" : "coach") +
                      "?id=" +
                      (logged == LoggedType.Client
                        ? state.login.client?.id
                        : state.login.coach?.id) ?? 0
                    }
                  >
                    Mon profil
                  </NavLink>
                  <Button
                    key="se-deconnecter"
                    onClick={(e) =>
                      needConfirmation(
                        "Êtes vous sûr de vouloir vous deconnecter ?",
                        () => {
                          e.preventDefault();
                          dispatch(DISCONNECT());
                          history.push("/");
                        },
                        () => { }
                      )
                    }
                  >
                    Se Déconnecter
                  </Button>
                </>
              ) : (
                <NavLink to="/acces-membre" exact>
                  Connexion
                </NavLink>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent='flex-end'>
            {logged === LoggedType.Coach ? (
              ""
            ) : (
              <IconButton sx={{ transform: 'scale(1.2)' }}
                onClick={(e) => {
                  setOpenShoppingModal(true);
                }}
              >
                <ShoppingCartOutlinedIcon /> (
                {state.reservation.card.length})
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          onClick={() => handleDrawerToggle()}
          onClose={() => handleDrawerToggle()}
          variant="temporary"
          open={mobileOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Popover
        anchorEl={cardAnchor}
        open={cardAnchor !== undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setCardAnchor(undefined)}
      >
        <Box style={{ width: "200", marginLeft: 10, marginRight: 5 }}>
          {state.reservation.packs.map((p, i) => (
            <Grid container key={i}>
              <Grid item xs={3}>
                <h3>{p.remaining} / 10</h3>
              </Grid>
              <Grid item xs={9}>
                <h5>Expire le {addMonths(new Date(p.purchaseDate))}</h5>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Popover>

      <Dialog
        open={openShoppingModal}
        onClose={() => setOpenShoppingModal(false)}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
        sx={[classes.popupForm]}
        scroll="body"
      >
        <div className="wrapper">
          <IconButton
            aria-label="close"
            onClick={() => setOpenShoppingModal(false)}
            sx={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id="customized-dialog-title"
            sx={{
              "&.MuiTypography-h6": {
                textAlign: "center",
                fontSize: "20px",
              },
            }}
          >
            Votre panier
          </DialogTitle>
          <Box className="content">
            <Shopping onClose={() => setOpenShoppingModal(false)} />
          </Box>
        </div>
      </Dialog>
    </Box>
  );
};

export default Header;
