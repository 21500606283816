import { Box, Container, Typography } from "@mui/material";
import classes from "../style";

export const CgvPage: React.FC = () => {
  return (
    <Box sx={classes.defiscalisation}>
      <Container maxWidth="xl">
        <Typography variant="h2">
          CONDITIONS GÉNÉRALES DE VENTE
        </Typography>
        <Box className="introduction">
          <p><strong>Date de dernière mise à jour :</strong> [Date de la dernière mise à jour des CGV]</p>

          <h2>Article 1 – MENTION LÉGALES</h2>
          <p>
            Le présent site, accessible à l’URL <a href="https://hoplacoaching.fr">https://hoplacoaching.fr</a> (le « Site »), est édité par :
          </p>
          <p>
            La société E15 société au capital social de 500 euros, inscrite au R.C.S. de Bobigny sous le numéro 918 422 197, dont le siège social est situé au
            128 avenue du docteur David Rosenfeld 93230 Romainville, représenté(e) par <strong>[Nom et prénom du représentant]</strong> dument habilité(e),
          </p>
          <p>(Ci-après désigné l’« Exploitant »).</p>
          <p>
            Le numéro individuel TVA intercommunautaire de l’Exploitant est : <strong>FR33918422197</strong>
          </p>
          <p>
            Le Site est hébergé par la société OVH SAS située 2 rue Kellermann - 59100 Roubaix - France.
          </p>
          <p>
            Le Directeur de la publication du Site est <strong>Calori Julien</strong>.
          </p>
          <p>
            L’Exploitant peut être joint au numéro de téléphone suivant <strong>06 70 84 81 85</strong> et à l’adresse mail suivante{' '}
            <a href="mailto:contact@hoplacoaching.fr">contact@hoplacoaching.fr</a>.
          </p>

          <h2>Article 2 – DISPOSITIONS GÉNÉRALES RELATIVES AUX PRÉSENTES CONDITIONS GÉNÉRALES</h2>
          <p>
            Les conditions générales de Vente (les « Conditions Générales de Vente », ou les « CGV ») sont applicables exclusivement à la vente en ligne des
            produits proposés par l’Exploitant sur le Site internet.
          </p>
          <p>
            Les CGV sont mises à la disposition des clients sur le Site où elles sont directement consultables et peuvent également lui être communiquées sur
            simple demande par tout moyen.
          </p>
          <p>
            Les CGV sont opposables au client qui reconnaît en avoir eu connaissance et les avoir acceptées avant de réserver une séance. La confirmation de
            la réservation vaut adhésion par l'acheteur aux CGV en vigueur au jour de la commande dont la conservation et la reproduction sont assurées par
            l’Exploitant.
          </p>

          <h2>Article 3 – DESCRIPTION DES « PRODUITS »</h2>
          <p>
            Le Site est un site de vente en ligne de séances de coaching (ci-après le(s) « Produit(s) ») ouvert à toute personne physique ou morale utilisant
            le Site (le « Client »).
          </p>
          <p>
            Les Produits présentés sur le Site font chacun l'objet d'un descriptif établi par l’Exploitant, mentionnant leurs caractéristiques essentielles.
            Les photographies illustrant, le cas échéant, les produits ne constituent pas un document contractuel.
          </p>
          <p>
            Le Client demeure responsable des modalités et des conséquences de son accès au Site notamment par l’Internet. Cet accès peut impliquer le paiement
            de frais à des prestataires techniques tels que notamment des fournisseurs d’accès à l’Internet, lesquels demeurent à sa charge. En outre, le Client
            devra fournir et être entièrement responsable des équipements nécessaires afin de se connecter au Site.
          </p>
          <p>
            Le Client reconnaît avoir vérifié que la configuration informatique qu’il utilise est sécurisée et en état de fonctionnement.
          </p>

          <h2>Article 4 – CREATION DE L’ESPACE CLIENT</h2>
          <p>
            Pour réserver une séance sur le Site, le Client doit au préalable créer son espace client personnel. Une fois créé, pour y accéder, le Client doit
            s’identifier en utilisant son identifiant et son mot de passe secret, personnel et confidentiel. Il appartient au Client de ne pas communiquer son
            identifiant et son mot de passe conformément aux dispositions de l’article DONNEES PERSONNELLES des présentes Conditions Générales.
          </p>
          <p>
            Chaque Client s'engage à conserver une stricte confidentialité sur les données, en particulier identifiant et mot de passe, lui permettant d'accéder
            à son espace client, le Client reconnaissant être le seul responsable de l'accès au Service par le biais de son identifiant et de son mot de passe,
            sauf fraude avérée. Chaque Client s'engage en outre à informer sans délai l’Exploitant dans l'hypothèse d'une perte, d'un détournement ou de
            l'utilisation frauduleuse de son identifiant et/ou mot de passe.
          </p>
          <p>
            Après la création de son espace client personnel, le Client recevra un email lui confirmant la création de son espace client.
          </p>
          <ul>
            <li>
              Délivrer des informations réelles, exactes, à jour au moment de leur saisie dans le formulaire d'inscription du service, et notamment à ne pas
              utiliser de faux noms ou adresses, ou encore des noms ou adresses sans y être autorisé.
            </li>
            <li>
              Maintenir à jour les données d'inscriptions en vue de garantir en permanence leur caractère réel, exact et à jour.
            </li>
          </ul>
          <p>
            Le Client s'engage en outre à ne pas rendre disponible ou distribuer des informations illicites ou répréhensibles (telles que des informations
            diffamatoires ou constitutive d'usurpation d'identité) ou encore nuisibles (telles que les virus). Dans le cas contraire, l’Exploitant sera en mesure
            de suspendre ou de résilier l'accès du Client au Site à ses torts exclusifs.
          </p>

          <h2>Article 5 – RESERVATIONS</h2>
          <p>
            L’Exploitant s’efforce de garantir une disponibilité optimale de ses Produits. Les séances sont valables dans la limite des disponibilités des
            coachs.
          </p>
          <p>
            Si en dépit des meilleurs efforts de l’Exploitant, une séance s’avérerait indisponible postérieurement à la réservation du Client, l’Exploitant en
            informera le Client par email, dans les meilleurs délais, et le Client pourra choisir le report de la séance avec le coach préalablement sélectionné,
            à un créneau horaire ou une date ultérieurs en accord avec le coach.
          </p>
          <p>Il est convenu que l’Exploitant n'est tenu à aucune indemnité d'annulation.</p>
          <p>
            À l’exception de toute mention contraire figurant dans ces Conditions Générales et sans préjudice du droit de rétractation prévu par la loi
            applicable, les réservations du Client sont fermes et définitives.
          </p>
          <p>
            Au moment de réserver une séance, le Client doit sélectionner le type de suivi choisi, l’ajouter à son panier. Le Client a la possibilité de vérifier
            le détail de sa réservation et son prix total, et de revenir aux pages précédentes pour éventuellement corriger le contenu de son panier, avant de
            le valider.
          </p>
          <p>
            Le Client s’engage à lire les Conditions Générales de Vente alors en vigueur avant de les accepter et de confirmer les modalités de sa réservation.
            La confirmation de la réservation entraîne acceptation des CGV et forme le contrat.
          </p>

          <h2>Article 6 – MODALITÉS DE PAIEMENT ET SÉCURISATION</h2>
          <p>
            Le Client reconnaît expressément que toute réservation effectuée sur le Site est une réservation avec obligation de paiement, qui nécessite le
            paiement d’un prix contre la séance de coaching sélectionnée.
          </p>
          <p>
            L’Exploitant a mis en place un partenariat avec le service Avance immédiate de l’URSSAF. La solution de paiement en ligne nécessite la création
            d’un compte sur un site tiers, avant de basculer sur le site{' '}
            <a href="https://avance-immediate.fr/auto-declaration/63ed37f6396fc90843f87212">
              https://avance-immediate.fr/auto-declaration/63ed37f6396fc90843f87212
            </a>
            . Une fois le compte validé, la séance de coaching réservée sur le site{' '}
            <a href="https://hoplacoaching.fr">https://hoplacoaching.fr</a>, l’Exploitant émettra la demande de paiement à la fin de la séance.
          </p>
          <p>
            L’URSSAF informe par l’envoi d’un mail ou SMS de la mise à disposition d’une demande de paiement au nom de l’Exploitant. La demande est vérifiable
            pour validation pendant 48 heures. Passé ce délai, la demande est automatiquement validée.
          </p>
          <p>
            Le montant du reste à la charge du Client est prélevé sur le compte bancaire du client par l’URSSAF. L’URSSAF versera la totalité de la somme à
            l’Exploitant.
          </p>

          <h2>Article 7 – PAIEMENT DU PRIX</h2>
          <p>
            Le prix des séances de coaching en vigueur lors de la réservation est indiqué en euros toutes taxes comprises (TTC). En cas de promotion,
            l’Exploitant s'engage à appliquer le prix promotionnel à toute réservation faite durant la période de la publicité faite pour la promotion.
          </p>
          <p>Le prix est payable en euros (€) exclusivement.</p>

          <h2>Article 8 – FORMATION DU CONTRAT</h2>
          <p>
            Le contrat entre l’Exploitant et le Client est formé au moment de l'envoi par l’Exploitant de la validation de sa réservation.
          </p>
          <p>
            L'attention du Client est particulièrement attirée sur le mode d'acceptation de la réservation effectuée sur le Site. Lorsque le Client réserve sa
            séance, il doit sélectionner l’heure et le type de coaching souhaité. Si le Client souhaite un coach en particulier, il lui est possible de
            sélectionner le coach en question et d’adapter les dates et horaires en fonction des disponibilités affichées du coach.
          </p>
          <p>
            Un récapitulatif de la séance lui est présenté avec le nom du coach, le type de séance choisi, l’adresse du Client, la date et l’heure ainsi que le
            prix. Une fois les informations vérifiées, le Client doit confirmer la séance en cliquant sur le bouton dédié. Le Client doit valider son panier
            dans lequel il retrouve la séance (Produit). Il est possible de renseigner un code de réduction et de cocher le mode de paiement par Avance
            immédiate. Le Client doit contrôler et éventuellement corriger le contenu de son panier (identification, quantité de produits sélectionnées, prix,
            modalités) avant de le valider en cliquant sur « valider le panier ». Il constitue une acceptation irrévocable et sans réserve de la commande par
            le Client.
          </p>
          <p>
            L'archivage des communications, des bons de commande et des factures est assuré par l’Exploitant sur un support fiable et durable de manière à
            constituer une copie fidèle et durable. Ces communications, bons de commande et factures peuvent être produits à titre de preuve du contrat. Sauf
            preuve contraire, les données enregistrées par l’Exploitant sur Internet ou par téléphone constituent la preuve de l'ensemble des transactions
            passées entre l’Exploitant et ses Clients.
          </p>

          <h2>Article 9 – ANNULATION, ABSENCE ET SUSPENSION</h2>
          <p>
            Le Client est tenu de respecter les rendez-vous et les horaires préalablement définis. En cas de retard de l’Intervenant (coach), celui-ci doit
            rattraper le temps perdu en fin de séance ou sur la séance suivante. En cas d’absence non prévue de l’Intervenant, celui-ci se doit de rattraper la
            séance.
          </p>
          <p>Tout retard du Client sera automatiquement décompté du temps de la séance. Au-delà de 30 minutes de retard, la séance sera annulée et facturée.</p>
          <p>En cas d’absence du Client, la séance sera facturée.</p>
          <p>
            l’Exploitant ne pourra être tenu responsable pour le retard de l’exécution ou annulation de la prestation en cas de force majeure telle que les
            catastrophes d’origine atmosphérique, un accident survenu sur le trajet de l’Intervenant ou tout autre cas de force majeure. Dans cette hypothèse,
            le Client sera alors prévenu le plus rapidement possible et la séance sera reportée à une date ultérieure.
          </p>
          <p>En cas d’annulation, le Client concerné recevra un appel (ou, en l’absence de réponse, un SMS) et la séance sera reportée à une date ultérieure.</p>
          <h2>Article 10 – SERVICE CLIENTS</h2>
          <p>
            Pour toute demande, suggestion ou réclamation, le Client peut contacter l’Exploitant par l’envoi d’un courrier électronique ou par voie postale :
          </p>
          <ul>
            <li>
              Par email en vous adressant à <a href="mailto:hopla.coaching@gmail.com">hopla.coaching@gmail.com</a> en indiquant son nom, numéro de téléphone,
              l’objet de sa demande.
            </li>
            <li>
              Par voie postale à l’adresse suivante : Hoplà! – 128 avenue du docteur David Rosenfeld 93230 Romainville.
            </li>
          </ul>

          <h2>Article 11 – ASSURANCE ET RESPONSABILITÉS</h2>
          <p>
            Le Client doit souscrire une police d’assurance responsabilité civile personnelle, le couvrant de tous les dommages qu’il pourrait causer à des
            tiers, de son propre fait, durant les prestations.
          </p>
          <p>
            L’Exploitant conseille par ailleurs à chaque Client de souscrire une assurance de dommage corporel liée à l’activité physique et sportive le couvrant
            pour sa propre intégrité physique.
          </p>
          <p>
            L’Exploitant a souscrit une assurance pour couvrir les dommages engageant sa responsabilité professionnelle. Les Intervenants qui ne sont pas
            salariés de l’Exploitant sont des professionnels indépendants dont l’Exploitant n’est pas responsable et qui doivent également souscrire et
            maintenir une assurance professionnelle.
          </p>
          <p>
            La responsabilité de l’Exploitant ne pourra être recherchée qu’en cas de démonstration d’un lien de causalité entre une faute lourde commise par
            l’Exploitant dans l’exécution du Contrat et le dommage subi par le Client.
          </p>
          <p>
            La responsabilité de l’Exploitant ne pourra notamment pas être recherchée :
            <ul>
              <li>en cas d’accident résultant de l’inobservation par le Client des consignes de l’Intervenant ;</li>
              <li>en cas d’accident intervenant hors des séances ou conseils prodigués par les Intervenants ;</li>
              <li>en cas d’actes ou omissions des Intervenants qui ne sont pas des salariés de l’Exploitant ;</li>
              <li>si le Client ne respecte pas les engagements qu’il prend et garanties qu’il donne au titre Contrat ;</li>
              <li>et/ou en cas de force majeure.</li>
            </ul>
          </p>
          <p>
            Les dommages indirects (tels que, sans que cette liste ne soit limitative, une perte de revenus, une perte de chance, un manque à gagner, tout
            préjudice financier ou tout préjudice immatériel) ne pourront pas faire l’objet d’une indemnisation de la part de l’Exploitant.
          </p>
          <p>
            L’Exploitant n’est responsable des engagements qu’il prend et des garanties qu’il donne au titre du Contrat. Le Client sera responsable en cas de
            dégradation occasionnée ou en cas de dommage matériel ou corporel résultant d’un acte volontaire ou involontaire survenant de son fait.
          </p>
          <p>
            Le Client s’engage par ailleurs à garantir et prendre en charge l’ensemble des condamnations et des frais de justice qui pourraient être encourus ou
            mis à la charge de l’Exploitant du fait des fautes, manquements ou autres actes survenant du fait du Client.
          </p>

          <h2>Article 12 – RÉFÉRENCES</h2>
          <p>
            Le Client autorise l’Exploitant à mentionner le nom du Client, son logo à titre de référence dans ses supports de communication (plaquette, site
            internet, proposition commerciale, relation avec la presse, communiqué de presse, dossier de presse, communication interne, etc.).
          </p>

          <h2>Article 13 – DISPOSITIONS GÉNÉRALES</h2>
          <h3>Intégralité de l’accord des parties</h3>
          <p>
            Les présentes Conditions Générales constituent un contrat régissant les relations entre le Client et l’Exploitant. Elles constituent l'intégralité
            des droits et obligations de la Société et de l’Exploitant relatifs à leur objet. Si une ou plusieurs stipulations des présentes Conditions
            Générales étaient déclarées nulles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente,
            les autres stipulations garderont toute leur force et leur portée.
          </p>
          <p>
            En outre, le fait pour une des parties aux présentes Conditions Générales de ne pas se prévaloir d'un manquement de l'autre partie à l'une quelconque
            des dispositions des présentes Conditions Générales ne saurait s'interpréter comme une renonciation de sa part à se prévaloir dans l'avenir d'un tel
            manquement.
          </p>

          <h3>Modifications des conditions</h3>
          <p>
            L’Exploitant se réserve le droit de modifier à tout moment et sans préavis le contenu du Site ou des services qui y sont disponibles, et/ou de
            cesser de manière temporaire ou définitive d’exploiter tout ou partie du Site.
          </p>
          <p>
            En outre, l’Exploitant se réserve le droit de modifier à tout moment et sans préavis la localisation du Site sur l’Internet, ainsi que les présentes
            Conditions Générales. Le Client est donc tenu par conséquent de se reporter aux présentes Conditions Générales avant toute utilisation du Site.
          </p>
          <p>
            Le Client reconnaît que l’Exploitant ne saurait être tenu responsable de quelque manière que ce soit envers lui ou tout tiers du fait de ces
            modifications, suspensions ou cessations.
          </p>
          <p>
            L’Exploitant conseille au Client de sauvegarder et/ou imprimer les présentes Conditions Générales pour une conservation sûre et durable, et pouvoir
            ainsi les invoquer à tout moment pendant l'exécution du contrat si besoin.
          </p>
          <p>
            Le fait pour l’Exploitant de ne pas se prévaloir de l’une quelconque des clauses du Contrat ne peut être interprété comme une renonciation à s’en
            prévaloir ultérieurement.
          </p>

          <h3>Réclamation - Médiation</h3>
          <p>
            En cas de litige, le Client doit adresser une réclamation au Service Client de l’Entreprise par l’envoi d’un courrier électronique ou par voie
            postale :
          </p>
          <ul>
            <li>
              Par email en vous adressant à <a href="mailto:hopla.coaching@gmail.com">hopla.coaching@gmail.com</a> en indiquant son nom, numéro de téléphone,
              l’objet de sa demande.
            </li>
            <li>
              Par voie postale à l’adresse suivante : Hoplà! – 128 avenue du docteur David Rosenfeld 93230 Romainville.
            </li>
          </ul>
          <p>
            En cas d'échec de la demande de réclamation auprès du Service Client ou en l'absence de réponse de ce service dans un délai de dix (10) jours, le
            Client peut soumettre la demande l'opposant à l’Exploitant au médiateur suivant :
          </p>
          <p>
            CMAP – Centre de médiation et d’arbitrage de Paris – près la Chambre de commerce et d’industrie de Paris Ile-de-France, dont les Parties ont eu
            connaissance et auquel elles déclarent adhérer.
          </p>
          <p>
            Les parties restent libres d'accepter ou de refuser le recours à la médiation ou d'accepter ou de refuser la solution proposée par le médiateur.
          </p>
          <p>
            Dans l’hypothèse d’un échec de la médiation, les différends seront portés devant les juridictions françaises compétentes.
          </p>

          <h3>Droit applicable</h3>
          <p>
            Ces Conditions Générales sont régies, interprétées et appliquées conformément au droit français.
          </p>
          <p>
            Toute modification de la législation ou de la réglementation en vigueur, ou toute décision d’un tribunal compétent qui invaliderait une ou
            plusieurs clauses des conditions générales de vente ne saurait affecter l’ensemble des conditions générales de vente. Une telle modification ou
            décision ne permet pas au Client de méconnaître les présentes conditions générales de vente.
          </p>

          <h3>Acceptation des conditions générales par le Client</h3>
          <p>
            Le Client reconnaît avoir lu attentivement les présentes Conditions Générales et confirme les accepter en s’inscrivant sur le Site. L’inscription
            ainsi que la réservation de séance lient contractuellement le Client par les termes des Conditions Générales.
          </p>
          <p>
            Les Conditions Générales applicables au Client sont celles disponibles à la date de la réservation sur le Site. Il est donc précisé que toute
            modification des Conditions Générales qui serait effectuée par l’Exploitant ne s’appliquera pas à toute séance réalisée antérieurement, sauf accord
            exprès du Client à l’origine d’une réservation donnée.
          </p>
          <p>
            Les conditions générales de vente sont consultables sur le Site et peuvent être modifiées à tout moment à la discrétion de l’Exploitant. Seule la
            dernière version sera applicable dès sa mise en ligne.
          </p>
          <p>
            Le fait, pour l’Exploitant, de ne pas se prévaloir d’une quelconque clause du Contrat ne peut être interprété comme une renonciation à s’en
            prévaloir ultérieurement.
          </p>
        </Box>
      </Container>
    </Box>
  );
};
