import { Box, Container, Typography, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import classes from "../../style";

export const Articles: React.FC = () => {
    const articles = [
        { id: 1, title: "Coach Sportif à Domicile en Île-de-France : Avantages et Bénéfices" },
        { id: 2, title: "Coaching Sportif à Domicile avec Avance Immédiate : Payez Moitié Prix" },
        { id: 3, title: "Coaching Sportif Personnalisé à Domicile : Une Solution Sur-Mesure pour Femmes Actives" },
        { id: 4, title: "Quels Sont les Tarifs d’un Coach Sportif à Domicile en Île-de-France ?" },
        { id: 5, title: "Perte de Poids avec un Coach Sportif à Domicile en Île-de-France" },
        { id: 6, title: "Coaching Bien-Être et Remise en Forme à Domicile pour Séniors en Île-de-France" }
    ];

    return (
        <Box sx={{ ...classes.defiscalisation, padding: "2rem 0" }}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" mt={5} gutterBottom align="center">
                    Liste des Articles
                </Typography>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                        gap: "1.5rem",
                        marginTop: "1rem",
                    }}
                >
                    {articles.map((article) => (
                        <Paper
                            key={article.id}
                            elevation={3}
                            sx={{
                                padding: "1.5rem",
                                borderRadius: "16px",
                                transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                                },
                            }}
                        >
                            <Link
                                to={`/article/${article.id}`}
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                            >
                                <Typography variant="h6" component="h2" gutterBottom>
                                    {article.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Lire l'article
                                </Typography>
                            </Link>
                        </Paper>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};
