import React, { FC } from "react";

import classes from "./style";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import Google from "../../images/svg/Google.svg";
import Fb from '../../images/svg/Fb.svg';
import Ig from '../../images/svg/Ig.svg';
import HoplaTheme from "../../theme";
import { useHistory } from "react-router";

const boxStyle = {
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'flex-end', 
  alignItems: 'center'
}

const boxRStyle = {
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'flex-start', 
  alignItems: 'center'
}

const gridStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const iconStyle = {
  width: '42px',
  height: '42px',
  margin: '6px'
}

const Footer: FC<{}> = () => {
  const matchDownSm = useMediaQuery(HoplaTheme.breakpoints.down("md"));
  const history = useHistory();

  const goTo = (url: string) => window.open(url, '_blank', 'noopener,noreferrer');

  return (
    <Box>
      <Grid container>
        {/* <Grid item xs={3} style={gridStyle}>
          <Box sx={boxStyle}>
            <Box onClick={() => history.push('/defiscalisation-immédiate')} style={{cursor: 'pointer'}}>
              <Typography variant="h5" color="black" textAlign='center'>
              Défiscalisez vos séances !
              </Typography>
            </Box>
            <Box onClick={() => history.push('/reservation')} style={{cursor: 'pointer'}}>
              <Typography variant="h5" color="black" mt={2} mb={2} textAlign='center'>
              Réservez une séance
              </Typography>
            </Box>
            <Box onClick={() => history.push('/acces-membre')} style={{cursor: 'pointer'}}>
              <Typography variant="h5" color="black" textAlign='center'>
              Connexion
              </Typography>
            </Box>
          </Box>
        </Grid> */}

        <Grid item xs={4} sm={5}/>
        <Grid item xs={4} sm={2} my={3}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            verticalAlign: 'middle',
            height: '100%', margin: '0% 10%'
          }}>
            <img
              alt="HopLa logo"
              src="Logo.svg"
              style={{cursor: 'pointer', width: '90%'}}
              onClick={() => {}} 
            />
          </Box>
        </Grid>
        <Grid item xs={4} sm={5}/>

      </Grid>

    <Box ml={2} style={{display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center'}}>
      <Box onClick={() => goTo('https://www.facebook.com/profile.php?id=100091255620023')} style={{cursor: 'pointer'}}>
        <img src={Fb} style={iconStyle} />
      </Box>
      <Box onClick={() => goTo('https://www.instagram.com/hopla.coaching/')} style={{cursor: 'pointer'}}>
        <img src={Ig} style={iconStyle} />
      </Box>
      <Box onClick={() => window.open('mailto:contact@hoplacoaching.fr')} style={{cursor: 'pointer'}}>
        <img src={Google} style={iconStyle} />
      </Box>
    </Box>

      <Container sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: !matchDownSm ? 'row' : 'column',
        mb: 3
      }}>
        <Box onClick={() => history.push('/conditions-generales-de-vente')} style={{cursor: 'pointer'}}>
            <Typography variant="h5">
            Mentions légales
            </Typography>
          </Box>
          { !matchDownSm && <Typography variant="h5" ml={2} mr={2}> | </Typography> }
          <Box onClick={() => history.push('/conditions-generales-de-vente-hoplacoaching')} style={{cursor: 'pointer'}}>
            <Typography variant="h5">
            Conditions générales de ventes
            </Typography>
          </Box>
      </Container>
    </Box>
  );
};

export default Footer;
